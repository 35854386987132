@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	body {
		font-family: 'Jost';
		font-size: 1.125rem;
	}
}

.btn {
	@apply font-bold py-4 px-10 rounded;
}

.btn-tab {
	@apply px-4 py-2 md:px-10 md:py-4;
}

.btn-primary {
	background: #ef0606;
}

@keyframes slideDown {
	from {
		transform: translateY(-100%);
	}
	to {
		transform: translateY(0);
	}
}

header.is-sticky {
	position: fixed;
	box-shadow: 0 5px 16px rgba(0, 0, 0, 0.5);
	background: rgba(0, 0, 0, 0.75);
	backdrop-filter: blur(10px);
	animation: slideDown 0.35s ease-out;
	top: 0;
	width: 100%;
}

.react-tabs__tab--selected {
	@apply btn-primary;
}

.react-tabs__tab--selected:focus-visible {
	border: none;
	outline: none;
}

.lines {
	position: relative;
	top: 14px;
	border-top: 1px solid #cdcdcd;
	width: 98%;
}

@media only screen and (max-width: 476px) {
	.lines {
		display: none;
	}
}

.lines::before {
	position: absolute;
	top: 4px;
	display: block;
	width: 100%;
	height: 1px;
	border-top: 1px solid #cdcdcd;
	content: '';
}

.carousel-item {
	height: 250px;
}

.carousel-item img {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.carousel-item:focus-visible {
	outline: none;
}

.slick-slider .slick-dots li button:before {
	font-size: 10px;
}

.slick-slider .slick-dots li.slick-active button:before {
	color: #ef0606;
}

.slick-slider .slick-prev,
.slick-slider .slick-next {
	top: 0;
	bottom: 0;
	transform: none;
	height: calc(100% - 8px);
	width: 50px;
	z-index: 1;
}

.slick-slider .slick-prev {
	left: 4px;
}

.slick-slider .slick-next {
	right: 4px;
}

.slick-slider .slick-prev:hover {
	background: linear-gradient(
		90deg,
		rgba(0, 0, 0, 0.5) 0%,
		rgba(255, 255, 255, 0) 100%
	);
}

.slick-slider .slick-prev:before,
.slick-slider .slick-next:before {
	font-size: 32px;
}

.slick-slider .slick-next:hover {
	background: linear-gradient(
		-90deg,
		rgba(0, 0, 0, 0.5) 0%,
		rgba(255, 255, 255, 0) 100%
	);
}

.ReactModal__Body--open {
	overflow: hidden;
}

.prev,
.next {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 0;
	z-index: 2;
	color: #ffffff;
}

.next {
	left: auto;
	right: 0;
}

.ReactModal__Overlay {
	z-index: 20 !important;
}

@media only screen and (max-width: 767px) {
	.menu {
		position: absolute;
		left: 0;
		top: 74px;
		background: white;
		padding: 20px;
		width: 100vw;
		box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
	}
}

@media only screen and (max-width: 767px) {
	.menu a {
		color: #000000;
		padding: 10px;
		display: block;
	}
}

.menu .active {
	@apply text-primary;
	font-weight: 600;
}

.szh-accordion__item-content {
	transition: height 0.25s cubic-bezier(0, 0, 0, 1);
}

.szh-accordion__item-btn {
	text-transform: uppercase;
	padding: 20px 0;
	border-bottom: 1px solid #000000;
	width: 100%;
	text-align: left;
}

.szh-accordion__item--expanded .szh-accordion__item-btn,
.szh-accordion__item-btn:hover {
	font-weight: bold;
}

.szh-accordion__item .szh-accordion__item-btn {
	position: relative;
}

.szh-accordion__item .szh-accordion__item-btn::before,
.szh-accordion__item .szh-accordion__item-btn::after {
	content: '';
	position: absolute;
	top: 36px;
	right: 11px;
	width: 24px;
	height: 2px;
	background: #000000;
}

.szh-accordion__item .szh-accordion__item-btn::after {
	width: 2px;
	height: 24px;
	right: 22px;
	top: 25px;
}

.szh-accordion__item.szh-accordion__item--expanded
	.szh-accordion__item-btn::after {
	display: none;
}

@media (min-width: 768px) {
	.szh-accordion__item-btn {
		font-size: 32px;
	}

	.szh-accordion__item .szh-accordion__item-btn::before {
		top: 48px;
		right: 35px;
	}

	.szh-accordion__item .szh-accordion__item-btn::after {
		top: 37px;
		right: 46px;
	}
}

table {
	border-collapse: collapse;
	width: 100%;
	font-family: Arial, sans-serif;
}
th,
td {
	padding: 10px 20px;
	text-align: left;
	border-bottom: 1px solid #ddd;
}
th {
	background-color: #f2f2f2;
}

/* Hover effect for table rows */
tr:hover {
	background-color: #f5f5f5;
}

/* Alternate row colors */
tr:nth-child(even) {
	background-color: #f9f9f9;
}
